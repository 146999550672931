interface FakeSimpleObject {
    id: number;
    name: string;
    imageUrl: string;
  }
  interface FakeObject {
    id: number;
    name: string;
    imageUrl: string;
    details: string;
    price: string;
  }
  export interface FakeObjectsRequest {
    currentPage: number;
    pageSize: number;
  }
  export interface FakeObjectsResponse {
    totalPages: number;
    currentPage: number;
    items: FakeSimpleObject[];
  }
  export interface FakeObjectDetailResponse {
    fakeObject: FakeObject
  }
  const fakeList: FakeObject[] = [
    { id: 1, name: 'Image 1', imageUrl: 'https://picsum.photos/id/1/200/300', details: "Details 1", price: "12€"},
    { id: 2, name: 'Image 2', imageUrl: 'https://picsum.photos/id/2/200/300', details: "Details 2", price: "12€" },
    { id: 3, name: 'Image 3', imageUrl: 'https://picsum.photos/id/3/200/300', details: "Details 3", price: "12€" },
    { id: 4, name: 'Image 4', imageUrl: 'https://picsum.photos/id/4/200/300', details: "Details 4", price: "12€" },
    { id: 5, name: 'Image 5', imageUrl: 'https://picsum.photos/id/5/200/300', details: "Details 5", price: "12€" },
    { id: 6, name: 'Image 6', imageUrl: 'https://picsum.photos/id/6/200/300', details: "Details 6", price: "12€" },
    { id: 7, name: 'Image 7', imageUrl: 'https://picsum.photos/id/7/200/300', details: "Details 7", price: "12€" },
    { id: 8, name: 'Image 8', imageUrl: 'https://picsum.photos/id/8/200/300', details: "Details 8", price: "12€" },
    { id: 9, name: 'Image 9', imageUrl: 'https://picsum.photos/id/9/200/300', details: "Details 9", price: "12€" },
    { id: 10, name: 'Image 10', imageUrl: 'https://picsum.photos/id/10/200/300', details: "Details 10", price: "12€" },
    { id: 11, name: 'Image 11', imageUrl: 'https://picsum.photos/id/11/200/300', details: "Details 11", price: "12€" },
    { id: 12, name: 'Image 12', imageUrl: 'https://picsum.photos/id/12/200/300', details: "Details 12", price: "12€" },
    { id: 13, name: 'Image 13', imageUrl: 'https://picsum.photos/id/13/200/300', details: "Details 13", price: "12€" },
    { id: 14, name: 'Image 14', imageUrl: 'https://picsum.photos/id/14/200/300', details: "Details 14", price: "12€" },
    { id: 15, name: 'Image 15', imageUrl: 'https://picsum.photos/id/15/200/300', details: "Details 15", price: "12€" },
    { id: 16, name: 'Image 16', imageUrl: 'https://picsum.photos/id/16/200/300', details: "Details 16", price: "12€" },
    { id: 17, name: 'Image 17', imageUrl: 'https://picsum.photos/id/17/200/300', details: "Details 17", price: "12€" },
    { id: 18, name: 'Image 18', imageUrl: 'https://picsum.photos/id/18/200/300', details: "Details 18", price: "12€" },
    { id: 19, name: 'Image 19', imageUrl: 'https://picsum.photos/id/19/200/300', details: "Details 19", price: "12€" },
    { id: 20, name: 'Image 20', imageUrl: 'https://picsum.photos/id/20/200/300', details: "Details 20", price: "12€" },
    { id: 21, name: 'Image 21', imageUrl: 'https://picsum.photos/id/21/200/300', details: "Details 21", price: "12€" },
    { id: 22, name: 'Image 22', imageUrl: 'https://picsum.photos/id/22/200/300', details: "Details 22", price: "12€" },
    // ... more objects
  ];
  
  export function getFakeObjects(request: FakeObjectsRequest): Promise<FakeObjectsResponse> {
    const { currentPage, pageSize } = request;

  
    const paginatedItems = fakeList.slice((currentPage-1) * pageSize, currentPage * pageSize);
    const totalItems = fakeList.length;
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate total pages
    const simplifiedItems = paginatedItems.map(item => ({
      id: item.id,
      name: item.name,
      imageUrl: item.imageUrl,
    }));
    const response: FakeObjectsResponse = {
      totalPages: totalPages, // Include totalPages in the response
      currentPage: currentPage,
      items: simplifiedItems,
    };
  
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 500); // Simulate a delay for API response
    });
  }

  
  export function getFakeDetail(id: number): Promise<FakeObjectDetailResponse | null> {
    const foundItem = fakeList.find(item => item.id === id);
  
    if (foundItem) {
      const response: FakeObjectDetailResponse = {
        fakeObject: foundItem, // Use the found item as part of the response
      };
  
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(response);
        }, 500); // Simulate a delay for API response
      });
    } else {
      return Promise.resolve(null); // Return null for non-existent id
    }
  }
  
  export type { FakeObject }; // Export the type for possible import in other files