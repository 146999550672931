import React, { useState, useEffect } from 'react';
//import logo from '../logo.svg';
import { TbProgress } from "react-icons/tb";
import { getFakeObjects, FakeObjectsRequest, FakeObjectsResponse } from '../repo/fakerepository';
import { useNavigate //useParams 
} from 'react-router-dom';

function PublicPage() {
    const navigate = useNavigate();

    const pageSize = 6; // Number of items per page
    const [pageData, setPageData] = useState<FakeObjectsResponse>({
        currentPage: 1,
        totalPages: 0, // Initialize totalPages
        items: [],
    });
    const [isLoading, setIsLoading] = useState(false); // Use state for isLoading
    useEffect(() => {
        setIsLoading(true); // Set isLoading to true when starting the API call

        const requestData: FakeObjectsRequest = {
            currentPage: pageData.currentPage,
            pageSize: pageSize,
        };

        getFakeObjects(requestData)
            .then((response) => {
                setPageData(response);
                setIsLoading(false); // Set isLoading to false when the API call is done
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false); // Set isLoading to false in case of an error
            });
    }, [pageData.currentPage, pageSize]);
    return (
        <div className="public-page">
            <h3>Public</h3>
            {isLoading ? ( // Use conditional rendering with curly braces
                /*<img src={logo} className="App-progress" alt="logo" />*/
                <TbProgress className="App-progress" />
            ) : (
                <div className="image-grid">
                    {pageData.items.map((imageObj) => (
                        <div key={imageObj.id} className="image-item" onClick={() =>
                            navigate(`/details/${imageObj.id}`)}>
                            <img src={imageObj.imageUrl} alt={imageObj.name} />
                            <p>{imageObj.name}</p>
                        </div>
                    ))}
                </div>
            )}

            <PaginationControls
                totalPages={pageData.totalPages || 0}
                currentPage={pageData.currentPage || 1}
                pageSize={pageSize}
                setPageData={setPageData}
                isLoading={isLoading}
            />
        </div>
    );
}
interface PaginationControlsProps {
    totalPages: number; // Use totalPages instead of totalItems
    currentPage: number;
    pageSize: number;
    setPageData: React.Dispatch<React.SetStateAction<FakeObjectsResponse>>;
    isLoading: boolean
}

function PaginationControls({ totalPages, currentPage, setPageData, isLoading }: PaginationControlsProps) {


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setPageData((prevData) => ({
                ...prevData,
                currentPage: prevData.currentPage - 1,
            }));
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setPageData((prevData) => ({
                ...prevData,
                currentPage: prevData.currentPage + 1,
            }));
        }
    };

    return (
        <div className="pagination-controls">
            <button onClick={handlePreviousPage} disabled={isLoading}>Previous</button>
            <span>
                Page {currentPage} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={isLoading}>Next</button>
        </div>
    );
}
export default PublicPage;