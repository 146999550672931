import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import PublicPage from './components/PublicPage';
import ProductDetailPage from './components/ProductDetailPage';
import CartPage from './components/CartPage'; 
import ProtectedPage from './components/ProtectedPage';
import { AiOutlineShoppingCart } from "react-icons/ai";


import {
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
// https://create-react-app.dev/docs/adding-typescript/
// $ npm start
import { AuthProvider, useAuth } from './auth2'; // Import from the new auth.ts file

const App = () => {
  //const [pageData, setPageData] = useState([]); // Set your fetched page data here 
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<PublicPage/>} />
          <Route path="/details/:itemId" element={<ProductDetailPage/>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/protected" element={
            <RequireAuth>
              <ProtectedPage />
            </RequireAuth>} />
        </Route>
      </Routes>
    </AuthProvider>

  )
}
function Layout() {
  return (

    <div className=".App-div">
      <table className="row-table">
      <tbody>
        <tr  className="row">
          <td className="square-cell">
            <img src={logo} className="App-logo" alt="logo" />
          </td>
          <td>
            <li className="link-list">
              <Link to="/">Public Page</Link>
              <Link to="/protected">Protected Page</Link>
            </li>
          </td>
          <td className="square-cell-fibo">
            <AuthStatus />
          </td>
          <td className="square-cell">
          <Link to="/cart">
               <div className="cart-icon-wrapper">
            <AiOutlineShoppingCart className='App-icon'/>
            </div>
          </Link>
          </td>
        </tr>
        </tbody>
      </table>
      <Outlet />
    </div>
  );
}

function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default App;

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    auth.signin(username, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
    console.log("apiEndpoint", apiEndpoint);
    try {
      const response = await fetch(`${apiEndpoint}/users/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: new URLSearchParams({
          username,
          password,
          // Add other required form data here if needed
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Data after login:", data); // Check if you're getting the expected data
        // Do something with the access token or refresh token
        
        auth.signin(username, () => {
          console.log("Navigating from:", from);
          navigate(from, { replace: true });
        });
      
      } else {
        // Handle error cases
        console.error("Login failed");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  return (
    <div>
      <p>You must log in to view the page at {from}</p>

      <form onSubmit={handleSubmit}>
        <label>
          Username: <input name="username" type="text" />
        </label>{" "}
        <label>
          Password: <input name="password" type="password" />
        </label>{" "}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}
