export interface CartItem {
    product_id: number;
    name: string;
    price: string;
    quantity: number;
  }
  
  export interface Cart {
    _id: number;
    user_id: number;
    items: CartItem[];
    created_at: string;
    updated_at: string;
  }
  
  const cartList: Cart[] = [];
  
  export function addCartItem(cartId: number, product_id: number, name: string, price: string, quantity: number): void {
    const cart = cartList.find(cart => cart._id === cartId);
    if (cart) {
      const existingItem = cart.items.find(item => item.product_id === product_id);
      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        cart.items.push({ product_id, name, price, quantity });
      }
    } else {
      cartList.push({
        _id: cartId,
        user_id: cartId, // You can set user_id appropriately
        items: [{ product_id, name, price, quantity }],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });
    }
  }
  export function removeCartItem(cartId: number, product_id: number): boolean {
    const cart = cartList.find(cart => cart._id === cartId);
    if (cart) {
      const itemIndex = cart.items.findIndex(item => item.product_id === product_id);
      if (itemIndex !== -1) {
        cart.items.splice(itemIndex, 1); // Remove the item from the cart's items array
        return true; // Item removed successfully
      }
    }
    return false; // Item not found in cart
  }
  export function getCart(cartId: number): Cart | undefined {
    return cartList.find(cart => cart._id === cartId);
  }