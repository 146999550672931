import React, { useState, useEffect } from 'react';
import { getCart, Cart, CartItem } from '../repo/cartrepository'; // Import the cart repository function and types

function CartPage() {
  const [cart, setCart] = useState<Cart | undefined>(undefined);

  useEffect(() => {
    const fetchedCart = getCart(1); // Replace with the appropriate cart ID logic
    setCart(fetchedCart);
  }, []);

  return (
    <div className="cart-page">
      <h2>Cart Items</h2>
      {cart && cart.items.length > 0 ? (
        <ul>
          {cart.items.map((item: CartItem, index: number) => (
            <li key={index}>
              <h3>{item.name}</h3>
              <p>Price: {item.price}</p>
              <p>Quantity: {item.quantity}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>Your cart is empty.</p>
      )}
    </div>
  );
}

export default CartPage;