import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFakeDetail, FakeObjectDetailResponse  } from '../repo/fakerepository';
import { TbProgress } from 'react-icons/tb';
import { BsPlus, BsDash, BsCartPlus } from 'react-icons/bs'; // Import icons for the buttons
import { addCartItem } from '../repo/cartrepository'; // Import the cart repository functions
//interface ProductDetailPageProps {
//  selectedItem: FakeObjectDetailResponse | null;
//}

function ProductDetailPage() {
  const { itemId } = useParams(); // Get the item ID from the URL
  const [selectedItem, setSelectedItem] = useState<FakeObjectDetailResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(1); // State to manage the quantity
  //const [cartItems, setCartItems] = useState<{ product_id: number, name: string, price: string, quantity: number }[]>([]);


  useEffect(() => {
    // Fetch the detail information for the selected item
    getFakeDetail(Number(itemId))
      .then(response => {
        setSelectedItem(response);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }, [itemId]);

  const incrementQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };
  const addToCart = () => {
    if (selectedItem) {
      addCartItem(1, selectedItem.fakeObject.id, selectedItem.fakeObject.name, selectedItem.fakeObject.price, quantity);
      // You may need to handle success or failure based on the repository response
    }
  };
  return (
    <div className="detail-page">
      {isLoading ? (
        <TbProgress className="App-progress" />
      ) : (
        <div>
          {selectedItem ? (
            <>
              <h2>{selectedItem.fakeObject.name}</h2>
              <img src={selectedItem.fakeObject.imageUrl} alt={selectedItem.fakeObject.name} />
              <p>{selectedItem.fakeObject.details}</p>
              <p>{selectedItem.fakeObject.price}</p>
              <div>
                <button onClick={decrementQuantity}><BsDash /></button>
                <span>{quantity}</span>
                <button onClick={incrementQuantity}><BsPlus /></button>
              </div>
              <button onClick={addToCart}><BsCartPlus /> Add to Cart</button>
            </>
          ) : (
            <p>Item not found</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ProductDetailPage;